// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BucketIcon-m_icon-eCZ99Zqx {
  filter: invert(1) !important;
}
.BucketIcon-m_icon-eCZ99Zqx.dontInvert {
  filter: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/svgs/BucketIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;AAAE;EACE,uBAAA;AAEJ","sourcesContent":[".icon {\n  filter: invert(1) !important;\n  &:global(.dontInvert) {\n    filter: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `BucketIcon-m_icon-eCZ99Zqx`
};
export default ___CSS_LOADER_EXPORT___;
