// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LockButton-m_inProgress-OE11RRFH {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/item-actions/LockButton.m.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".inProgress {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inProgress": `LockButton-m_inProgress-OE11RRFH`
};
export default ___CSS_LOADER_EXPORT___;
