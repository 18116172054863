// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUpload-m_fileInput-sASDoWgY {
  text-align: center;
  padding: 8px;
  border: 1px dashed #999;
  width: 100%;
  box-sizing: border-box;
}

.FileUpload-m_instructions-rlatGaJM {
  font-weight: bold;
}

.FileUpload-m_dragActive-Lp9n0cNM {
  border-style: solid;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/FileUpload.m.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".fileInput {\n  text-align: center;\n  padding: 8px;\n  border: 1px dashed #999;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.instructions {\n  font-weight: bold;\n}\n\n.dragActive {\n  border-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `FileUpload-m_fileInput-sASDoWgY`,
	"instructions": `FileUpload-m_instructions-rlatGaJM`,
	"dragActive": `FileUpload-m_dragActive-Lp9n0cNM`
};
export default ___CSS_LOADER_EXPORT___;
