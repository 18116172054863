// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/engram_outline.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DraggableInventoryItem-m_engram-BlmgtRCV:hover {
  outline: none;
  contain: layout style;
}
.DraggableInventoryItem-m_engram-BlmgtRCV:hover::before {
  content: "";
  position: absolute;
  width: var(--item-size);
  height: var(--item-size);
  box-sizing: border-box;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.DraggableInventoryItem-m_cantDrag-Q3dOPgdG {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/inventory/DraggableInventoryItem.m.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EAGA,qBAAA;AAFF;AAKE;EACE,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,wBAAA;EACA,sBAAA;EACA,mDAAA;AAHJ;;AAOA;EACE,eAAA;AAJF","sourcesContent":[".engram:hover {\n  // don't display the default outline when hovering over a draggable subclass item\n  outline: none;\n\n  // allow the pseudo-element to render outside the bounds of the item\n  contain: layout style;\n\n  // render a hexagon-shaped pseudo-element to act as the border\n  &::before {\n    content: '';\n    position: absolute;\n    width: var(--item-size);\n    height: var(--item-size);\n    box-sizing: border-box;\n    background: url('images/engram_outline.svg');\n  }\n}\n\n.cantDrag {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"engram": `DraggableInventoryItem-m_engram-BlmgtRCV`,
	"cantDrag": `DraggableInventoryItem-m_cantDrag-Q3dOPgdG`
};
export default ___CSS_LOADER_EXPORT___;
