// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!../dim-ui/common.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cost-m_cost-riWXn680 {
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
}
.Cost-m_cost-riWXn680 img {
  height: 12px;
  width: 12px;
  margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/vendors/Cost.m.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;EACA,yBAAA;EACA,eAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAEJ","sourcesContent":[".cost {\n  composes: flexRow from '../dim-ui/common.m.scss';\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 10px;\n  img {\n    height: 12px;\n    width: 12px;\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cost": `Cost-m_cost-riWXn680 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["flexRow"]}`
};
export default ___CSS_LOADER_EXPORT___;
