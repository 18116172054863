// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandableTextBlock-m_textBlockWrapper-T_EU01Tt {
  position: relative;
  overflow: hidden;
}
.ExpandableTextBlock-m_textBlockWrapper-T_EU01Tt:hover {
  cursor: pointer;
}
.ExpandableTextBlock-m_textBlockWrapper-T_EU01Tt::after {
  content: "";
  position: absolute;
  inset: 0;
  box-shadow: inset 0 -31px 20px -20px black;
}
.ExpandableTextBlock-m_textBlockWrapper-T_EU01Tt.ExpandableTextBlock-m_open-ldUUaFkM:hover {
  cursor: default;
}
.ExpandableTextBlock-m_textBlockWrapper-T_EU01Tt.ExpandableTextBlock-m_open-ldUUaFkM::after {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/ExpandableTextBlock.m.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,0CAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,aAAA;AAEJ","sourcesContent":[".textBlockWrapper {\n  position: relative;\n  overflow: hidden;\n  &:hover {\n    cursor: pointer;\n  }\n  &::after {\n    content: '';\n    position: absolute;\n    inset: 0;\n    box-shadow: inset 0 -31px 20px -20px black;\n  }\n  &.open:hover {\n    cursor: default;\n  }\n  &.open::after {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBlockWrapper": `ExpandableTextBlock-m_textBlockWrapper-T_EU01Tt`,
	"open": `ExpandableTextBlock-m_open-ldUUaFkM`
};
export default ___CSS_LOADER_EXPORT___;
