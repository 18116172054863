// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TriageFactors-m_modSlotIcon-AgbZwWE9 {
  height: 20px;
  width: 20px;
  display: block;
}

.TriageFactors-m_inlineIcon2-TMARyiUi {
  height: 18px;
  width: auto;
  vertical-align: middle;
}

.TriageFactors-m_factorIcon-YaKrzORN {
  height: 20px;
  width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/item-triage/TriageFactors.m.scss"],"names":[],"mappings":"AAAA;EAGE,YAAA;EACA,WAAA;EACA,cAAA;AADF;;AAGA;EACE,YAAA;EACA,WAAA;EAEA,sBAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;AADF","sourcesContent":[".modSlotIcon {\n  // height: 18px;\n  // width: 18px;\n  height: 20px;\n  width: 20px;\n  display: block;\n}\n.inlineIcon2 {\n  height: 18px;\n  width: auto;\n  // margin: -3px 0;\n  vertical-align: middle;\n}\n\n.factorIcon {\n  height: 20px;\n  width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modSlotIcon": `TriageFactors-m_modSlotIcon-AgbZwWE9`,
	"inlineIcon2": `TriageFactors-m_inlineIcon2-TMARyiUi`,
	"factorIcon": `TriageFactors-m_factorIcon-YaKrzORN`
};
export default ___CSS_LOADER_EXPORT___;
