// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpecialtyModSlotIcon-m_specialtyModIcon-fYqVTWi_ {
  background-position: center;
  display: inline-block;
  background-size: 150%;
  border-radius: 50%;
}

.SpecialtyModSlotIcon-m_lowRes-HDygQv7i {
  background-size: 196% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/SpecialtyModSlotIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,qBAAA;EACA,qBAAA;EACA,kBAAA;AACF;;AAIA;EACE,gCAAA;AADF","sourcesContent":[".specialtyModIcon {\n  background-position: center;\n  display: inline-block;\n  background-size: 150%;\n  border-radius: 50%;\n  // This causes rendering artifacts (wrong z-order, repaint on scroll)\n  // filter: drop-shadow(0 0 0 white);\n}\n\n.lowRes {\n  background-size: 196% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"specialtyModIcon": `SpecialtyModSlotIcon-m_specialtyModIcon-fYqVTWi_`,
	"lowRes": `SpecialtyModSlotIcon-m_lowRes-HDygQv7i`
};
export default ___CSS_LOADER_EXPORT___;
