// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-socket {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/Socket.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF","sourcesContent":["// An individual socket. Global class because it's currently directly overridden\n// in places like Compare.\n.item-socket {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
