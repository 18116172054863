// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WishListEntry-m_wishlist-VehDTIoJ {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
}
.WishListEntry-m_wishlist-VehDTIoJ > input {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/armory/WishListEntry.m.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AACF;AACE;EACE,OAAA;AACJ","sourcesContent":[".wishlist {\n  width: 100%;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n\n  > input {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wishlist": `WishListEntry-m_wishlist-VehDTIoJ`
};
export default ___CSS_LOADER_EXPORT___;
