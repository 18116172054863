// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!../dim-ui/common.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppInstallBanner-m_banner-wMpWy0K8 {
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  text-decoration: none;
  background: rgb(30, 32, 43);
  border-bottom: 1px solid rgb(104, 111, 139);
}
.AppInstallBanner-m_banner-wMpWy0K8 > *:first-child {
  flex: 1;
}

.AppInstallBanner-m_hideButton-A36AZBrK {
  margin-left: 16px;
  color: var(--theme-text);
}`, "",{"version":3,"sources":["webpack://./src/app/shell/AppInstallBanner.m.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;EAKA,WAAA;EACA,iBAAA;EACA,sBAAA;EACA,qBAAA;EACA,2BAAA;EACA,2CAAA;AAHF;AANE;EACE,OAAA;AAQJ;;AAGA;EAEE,iBAAA;EACA,wBAAA;AAAF","sourcesContent":[".banner {\n  composes: flexRow from '../dim-ui/common.m.scss';\n  align-items: center;\n  > *:first-child {\n    flex: 1;\n  }\n\n  width: 100%;\n  padding: 8px 16px;\n  box-sizing: border-box;\n  text-decoration: none;\n  background: rgb(30, 32, 43);\n  border-bottom: 1px solid rgb(104, 111, 139);\n}\n\n.hideButton {\n  composes: resetButton from '../dim-ui/common.m.scss';\n  margin-left: 16px;\n  color: var(--theme-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `AppInstallBanner-m_banner-wMpWy0K8 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["flexRow"]}`,
	"hideButton": `AppInstallBanner-m_hideButton-A36AZBrK ${___CSS_LOADER_ICSS_IMPORT_0___.locals["resetButton"]}`
};
export default ___CSS_LOADER_EXPORT___;
