// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllWishlistRolls-m_roll-cqcDisN3 {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.AllWishlistRolls-m_orGroup-PJxImUbw {
  border: 1px solid #333;
  border-radius: calc(0.32 * var(--item-size) + 2px);
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/armory/AllWishlistRolls.m.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;;AAEA;EACE,sBAAA;EACA,kDAAA;EACA,YAAA;AACF","sourcesContent":[".roll {\n  display: flex;\n  flex-direction: row;\n  gap: 2px;\n}\n\n.orGroup {\n  border: 1px solid #333;\n  border-radius: calc((16 / 50 * var(--item-size)) + 2px);\n  padding: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roll": `AllWishlistRolls-m_roll-cqcDisN3`,
	"orGroup": `AllWishlistRolls-m_orGroup-PJxImUbw`
};
export default ___CSS_LOADER_EXPORT___;
