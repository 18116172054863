// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!../dim-ui/common.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.api-permission-prompt-m_buttons-LXEXiZpg {
  justify-content: space-evenly;
}
.api-permission-prompt-m_buttons-LXEXiZpg > * {
  margin: 10px 8px 0 0;
}
.api-permission-prompt-m_buttons-LXEXiZpg > *:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-api/api-permission-prompt.m.scss"],"names":[],"mappings":"AAAA;EAEE,6BAAA;AACF;AACE;EACE,oBAAA;AACJ;AAAI;EACE,eAAA;AAEN","sourcesContent":[".buttons {\n  composes: flexRow from '../dim-ui/common.m.scss';\n  justify-content: space-evenly;\n\n  > * {\n    margin: 10px 8px 0 0;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `api-permission-prompt-m_buttons-LXEXiZpg ${___CSS_LOADER_ICSS_IMPORT_0___.locals["flexRow"]}`
};
export default ___CSS_LOADER_EXPORT___;
