// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageLoading-m_pageLoading-JrbU3yLa {
  position: relative;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/PageLoading.m.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;AACF","sourcesContent":[".pageLoading {\n  position: relative;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageLoading": `PageLoading-m_pageLoading-JrbU3yLa`
};
export default ___CSS_LOADER_EXPORT___;
