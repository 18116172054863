// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StoreIcon-m_classIcon-VWRz2oW7 {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.StoreIcon-m_label-xQM8D1vm {
  position: relative;
  font-weight: bold;
  font-size: 13px;
  text-shadow: 1px 1px 2px black;
}

.StoreIcon-m_dimmedBg-xpPAk0QN {
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/character-tile/StoreIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,8BAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".classIcon {\n  width: 16px;\n  height: 16px;\n  margin-right: 4px;\n}\n\n.label {\n  position: relative;\n  font-weight: bold;\n  font-size: 13px;\n  text-shadow: 1px 1px 2px black;\n}\n\n.dimmedBg {\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classIcon": `StoreIcon-m_classIcon-VWRz2oW7`,
	"label": `StoreIcon-m_label-xQM8D1vm`,
	"dimmedBg": `StoreIcon-m_dimmedBg-xpPAk0QN`
};
export default ___CSS_LOADER_EXPORT___;
