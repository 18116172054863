// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Destiny-m_content-DMIbg7Wz {
  -webkit-user-select: none;
          user-select: none;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}`, "",{"version":3,"sources":["webpack://./src/app/shell/Destiny.m.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;UAAA,iBAAA;EACA,uCAAA;EACA,yCAAA;AACF","sourcesContent":[".content {\n  user-select: none;\n  padding-left: env(safe-area-inset-left);\n  padding-right: env(safe-area-inset-right);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Destiny-m_content-DMIbg7Wz`
};
export default ___CSS_LOADER_EXPORT___;
