// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivityModifier-m_modifier-d5WKn98_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2px;
}
.ActivityModifier-m_modifier-d5WKn98_ img {
  width: 16px;
  height: 16px;
  margin: 1px 6px 0 2px;
}
.ActivityModifier-m_modifier-d5WKn98_.ActivityModifier-m_small-rjk1YqW5 {
  opacity: 0.5;
  margin-bottom: 0;
}
.ActivityModifier-m_modifier-d5WKn98_.ActivityModifier-m_small-rjk1YqW5 img {
  width: 12px;
  height: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/progress/ActivityModifier.m.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,qBAAA;AACJ;AAEE;EACE,YAAA;EACA,gBAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN","sourcesContent":[".modifier {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  margin-bottom: 2px;\n\n  img {\n    width: 16px;\n    height: 16px;\n    margin: 1px 6px 0 2px;\n  }\n\n  &.small {\n    opacity: 0.5;\n    margin-bottom: 0;\n\n    img {\n      width: 12px;\n      height: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modifier": `ActivityModifier-m_modifier-d5WKn98_`,
	"small": `ActivityModifier-m_small-rjk1YqW5`
};
export default ___CSS_LOADER_EXPORT___;
