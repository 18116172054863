// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomStatWeights-m_statWeightRow-G3g415_G {
  display: flex;
  align-items: center;
  gap: 2px;
}
.CustomStatWeights-m_statWeightRow-G3g415_G > * {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomStatWeights-m_statWeightRow-G3g415_G .CustomStatWeights-m_divider-oDq49iF2::after {
  content: "+";
  opacity: 0.7;
}
.CustomStatWeights-m_statWeightRow-G3g415_G img {
  height: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/CustomStatWeights.m.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAEE;EACE,YAAA;EACA,YAAA;AAAJ;AAGE;EACE,aAAA;AADJ","sourcesContent":[".statWeightRow {\n  display: flex;\n  align-items: center;\n  gap: 2px;\n\n  & > * {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .divider::after {\n    content: '+';\n    opacity: 0.7;\n  }\n\n  img {\n    height: 1.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statWeightRow": `CustomStatWeights-m_statWeightRow-G3g415_G`,
	"divider": `CustomStatWeights-m_divider-oDq49iF2`
};
export default ___CSS_LOADER_EXPORT___;
