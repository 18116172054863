// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemCategoryIcon-m_itemCategoryIcon-EezdngDX {
  width: auto;
  height: 100%;
  filter: invert(1) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/ItemCategoryIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,4BAAA;AACF","sourcesContent":[".itemCategoryIcon {\n  width: auto;\n  height: 100%;\n  filter: invert(1) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemCategoryIcon": `ItemCategoryIcon-m_itemCategoryIcon-EezdngDX`
};
export default ___CSS_LOADER_EXPORT___;
