// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!../dim-ui/common.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemPerks-m_itemPerk-DYdig8Zd {
  gap: 8px;
}
.ItemPerks-m_itemPerk-DYdig8Zd img {
  height: 48px;
  width: 48px;
}
.ItemPerks-m_itemPerk-DYdig8Zd > *:nth-child(2) {
  flex: 1;
}

.ItemPerks-m_itemPerkName-LpjraQDq {
  font-weight: bold;
  margin-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/ItemPerks.m.scss"],"names":[],"mappings":"AAAA;EAEE,QAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AAEE;EACE,OAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,kBAAA;AADF","sourcesContent":[".itemPerk {\n  composes: flexRow from '../dim-ui/common.m.scss';\n  gap: 8px;\n\n  img {\n    height: 48px;\n    width: 48px;\n  }\n\n  > *:nth-child(2) {\n    flex: 1;\n  }\n}\n\n.itemPerkName {\n  font-weight: bold;\n  margin-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemPerk": `ItemPerks-m_itemPerk-DYdig8Zd ${___CSS_LOADER_ICSS_IMPORT_0___.locals["flexRow"]}`,
	"itemPerkName": `ItemPerks-m_itemPerkName-LpjraQDq`
};
export default ___CSS_LOADER_EXPORT___;
