// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!./SearchBar.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainSearchBarActions-m_count-qUokWbK9 {
  color: #999;
  padding-left: 8px;
}
@media (max-width: 1000px) {
  .MainSearchBarActions-m_count-qUokWbK9 {
    display: none;
  }
}

.MainSearchBarActions-m_resultButton-aDZYDlVc {
  display: flex;
  align-items: center;
}
.MainSearchBarActions-m_resultButton-aDZYDlVc > span:first-child {
  margin-right: 4px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/search/MainSearchBarActions.m.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;AACF;AACE;EAJF;IAKI,aAAA;EAEF;AACF;;AACA;EAEE,aAAA;EACA,mBAAA;AAEF;AAAE;EACE,4BAAA;AAEJ","sourcesContent":[".count {\n  color: #999;\n  padding-left: 8px;\n\n  @media (max-width: 1000px) {\n    display: none;\n  }\n}\n\n.resultButton {\n  composes: filterBarButton from '../search/SearchBar.m.scss';\n  display: flex;\n  align-items: center;\n\n  > span:first-child {\n    margin-right: 4px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": `MainSearchBarActions-m_count-qUokWbK9`,
	"resultButton": `MainSearchBarActions-m_resultButton-aDZYDlVc ${___CSS_LOADER_ICSS_IMPORT_0___.locals["filterBarButton"]}`
};
export default ___CSS_LOADER_EXPORT___;
