// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmoteSockets-m_emoteWheel-zF0pp9jT {
  margin-left: 13px;
  display: grid;
  gap: 4px;
  place-items: center;
  grid-template-columns: min-content min-content min-content;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas: "  .      slot0     .  " "slot2 collection slot3" "  .      slot1     .  ";
}

.EmoteSockets-m_collectionIcon-q4Tvr2he {
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/EmoteSockets.m.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,0DAAA;EACA,uDAAA;EACA,+FACE;AAAJ;;AAKA;EACE,SAAA;AAFF","sourcesContent":[".emoteWheel {\n  margin-left: 13px;\n  display: grid;\n  gap: 4px;\n  place-items: center;\n  grid-template-columns: min-content min-content min-content;\n  grid-template-rows: min-content min-content min-content;\n  grid-template-areas:\n    '  .      slot0     .  '\n    'slot2 collection slot3'\n    '  .      slot1     .  ';\n}\n\n.collectionIcon {\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emoteWheel": `EmoteSockets-m_emoteWheel-zF0pp9jT`,
	"collectionIcon": `EmoteSockets-m_collectionIcon-q4Tvr2he`
};
export default ___CSS_LOADER_EXPORT___;
