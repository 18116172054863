// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmblemPreview-m_container-rR__fuOw {
  position: relative;
  width: min-content;
}

.EmblemPreview-m_banner-foc6jiaW {
  position: absolute;
  top: -1px;
  right: 4px;
  left: auto;
}

.EmblemPreview-m_value-vnAqpgMh {
  position: absolute;
  right: 25px;
  top: 27px;
  width: 50%;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/EmblemPreview.m.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,iBAAA;AACF","sourcesContent":[".container {\n  position: relative;\n  width: min-content;\n}\n\n.banner {\n  position: absolute;\n  top: -1px;\n  right: 4px;\n  left: auto;\n}\n\n.value {\n  position: absolute;\n  right: 25px;\n  top: 27px;\n  width: 50%;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmblemPreview-m_container-rR__fuOw`,
	"banner": `EmblemPreview-m_banner-foc6jiaW`,
	"value": `EmblemPreview-m_value-vnAqpgMh`
};
export default ___CSS_LOADER_EXPORT___;
