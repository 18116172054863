// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RatingIcon-m_godroll-Ix_a_6E_ {
  font-size: 0.8em !important;
  margin-right: auto;
  color: var(--theme-item-polaroid-godroll);
}

.RatingIcon-m_trashlist-hoMvUHFR {
  font-size: 0.8em !important;
  margin-right: auto;
  color: #d14334;
}`, "",{"version":3,"sources":["webpack://./src/app/inventory/RatingIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,kBAAA;EACA,yCAAA;AACF;;AAEA;EACE,2BAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":[".godroll {\n  font-size: 0.8em !important;\n  margin-right: auto;\n  color: var(--theme-item-polaroid-godroll);\n}\n\n.trashlist {\n  font-size: 0.8em !important;\n  margin-right: auto;\n  color: #d14334;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"godroll": `RatingIcon-m_godroll-Ix_a_6E_`,
	"trashlist": `RatingIcon-m_trashlist-hoMvUHFR`
};
export default ___CSS_LOADER_EXPORT___;
