// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!../search/SearchBar.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemActionsDropdown-m_storeIcon-xSqmQPqY {
  margin-right: 4px;
}

.ItemActionsDropdown-m_dropdownButton-OfCpvGDw {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/item-actions/ItemActionsDropdown.m.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EAEE,SAAA;AACF","sourcesContent":[".storeIcon {\n  margin-right: 4px;\n}\n\n.dropdownButton {\n  composes: filterBarButton from '../search/SearchBar.m.scss';\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"storeIcon": `ItemActionsDropdown-m_storeIcon-xSqmQPqY`,
	"dropdownButton": `ItemActionsDropdown-m_dropdownButton-OfCpvGDw ${___CSS_LOADER_ICSS_IMPORT_0___.locals["filterBarButton"]}`
};
export default ___CSS_LOADER_EXPORT___;
