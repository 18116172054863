// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InGameLoadoutIcon-m_wrapper-wB2_9gw5 {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.InGameLoadoutIcon-m_wrapper-wB2_9gw5 img {
  display: block;
}

.InGameLoadoutIcon-m_index-Q6FVQlCA {
  position: absolute;
  bottom: -3%;
  right: 3%;
  font-weight: bold;
  font-size: 30%;
  text-shadow: 0 0 5px #000;
}

.InGameLoadoutIcon-m_iconImg-_Y1oSarh {
  background-size: contain;
}`, "",{"version":3,"sources":["webpack://./src/app/loadout/ingame/InGameLoadoutIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,wBAAA;EAAA,mBAAA;AACF;AAAE;EACE,cAAA;AAEJ;;AACA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;AAEF;;AACA;EACE,wBAAA;AAEF","sourcesContent":[".wrapper {\n  position: relative;\n  width: fit-content;\n  height: fit-content;\n  img {\n    display: block;\n  }\n}\n.index {\n  position: absolute;\n  bottom: -3%;\n  right: 3%;\n  font-weight: bold;\n  font-size: 30%;\n  text-shadow: 0 0 5px #000;\n}\n\n.iconImg {\n  background-size: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `InGameLoadoutIcon-m_wrapper-wB2_9gw5`,
	"index": `InGameLoadoutIcon-m_index-Q6FVQlCA`,
	"iconImg": `InGameLoadoutIcon-m_iconImg-_Y1oSarh`
};
export default ___CSS_LOADER_EXPORT___;
