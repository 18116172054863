// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemMoveAmount-m_moveAmount-eiVHFpW3 {
  margin: 6px 8px;
  display: flex;
  flex-direction: column;
}
.ItemMoveAmount-m_moveAmount-eiVHFpW3 label {
  margin-bottom: 2px;
}
.ItemMoveAmount-m_moveAmount-eiVHFpW3 input[type=text] {
  width: 3em;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/ItemMoveAmount.m.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,UAAA;AAEJ","sourcesContent":[".moveAmount {\n  margin: 6px 8px;\n  display: flex;\n  flex-direction: column;\n  label {\n    margin-bottom: 2px;\n  }\n  input[type='text'] {\n    width: 3em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moveAmount": `ItemMoveAmount-m_moveAmount-eiVHFpW3`
};
export default ___CSS_LOADER_EXPORT___;
