// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertIcon-m_alertIcon-It3cw5SO {
  color: yellow;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/AlertIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF","sourcesContent":[".alertIcon {\n  color: yellow;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertIcon": `AlertIcon-m_alertIcon-It3cw5SO`
};
export default ___CSS_LOADER_EXPORT___;
