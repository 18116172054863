// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemFeedPage-m_page-ZrexunCV {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/item-feed/ItemFeedPage.m.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;AACF","sourcesContent":[".page {\n  composes: dim-page from global;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `ItemFeedPage-m_page-ZrexunCV dim-page`
};
export default ___CSS_LOADER_EXPORT___;
