// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HelpLink-m_helpLink-rXN0y5jg {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/HelpLink.m.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".helpLink {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpLink": `HelpLink-m_helpLink-rXN0y5jg`
};
export default ___CSS_LOADER_EXPORT___;
