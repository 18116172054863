// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SetFilterButton-m_setFilterButton-WzIBDZx5 {
  display: inline-block;
  cursor: pointer;
  color: #888;
  background-color: #222;
  padding: 0 6px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
}
.SetFilterButton-m_setFilterButton-WzIBDZx5:hover {
  background-color: #68a0b7;
  color: #222;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/SetFilterButton.m.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,eAAA;EACA,WAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EAKA,qBAAA;AAHF;AADE;EACE,yBAAA;EACA,WAAA;AAGJ","sourcesContent":[".setFilterButton {\n  display: inline-block;\n  cursor: pointer;\n  color: #888;\n  background-color: #222;\n  padding: 0 6px;\n  border-radius: 4px;\n  text-align: center;\n  &:hover {\n    background-color: #68a0b7;\n    color: #222;\n  }\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setFilterButton": `SetFilterButton-m_setFilterButton-WzIBDZx5`
};
export default ___CSS_LOADER_EXPORT___;
