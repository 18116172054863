// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!./ActionButton.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionButtons-m_entry-LZgSV1l7 {
}

.ActionButtons-m_label-US8Iulse {
  margin-left: 8px;
}

.ActionButtons-m_tagSelectorLabelHidden-xLuS3UlO {
  padding: 0 !important;
}
.ActionButtons-m_tagSelectorLabelHidden-xLuS3UlO .dim-button {
  border-radius: 0;
  padding: 6px 8px;
}
.ActionButtons-m_tagSelectorLabelHidden-xLuS3UlO .dim-button > div > .app-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin: 0;
  height: 24px;
  width: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/item-actions/ActionButtons.m.scss"],"names":[],"mappings":"AAAA;AAEA;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,qBAAA;AACF;AAAE;EACE,gBAAA;EACA,gBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;AAGN","sourcesContent":[".entry {\n  composes: entry from './ActionButton.m.scss';\n}\n\n.label {\n  margin-left: 8px;\n}\n\n.tagSelectorLabelHidden {\n  padding: 0 !important;\n  :global(.dim-button) {\n    border-radius: 0;\n    padding: 6px 8px;\n    > div > :global(.app-icon) {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      font-size: 16px;\n      margin: 0;\n      height: 24px;\n      width: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entry": `ActionButtons-m_entry-LZgSV1l7 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["entry"]}`,
	"label": `ActionButtons-m_label-US8Iulse`,
	"tagSelectorLabelHidden": `ActionButtons-m_tagSelectorLabelHidden-xLuS3UlO`
};
export default ___CSS_LOADER_EXPORT___;
