// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemTagSelector-m_itemTagSelector-g1WHwbjb {
  cursor: default;
}
.ItemTagSelector-m_itemTagSelector-g1WHwbjb button {
  min-width: 10em;
  width: 100%;
}

.ItemTagSelector-m_item-P2wsn6dh {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
.ItemTagSelector-m_item-P2wsn6dh > *:nth-child(2) {
  flex: 1;
  text-align: left;
}
.ItemTagSelector-m_item-P2wsn6dh .app-icon {
  margin-top: 1px;
  margin-right: 8px;
}

.ItemTagSelector-m_null-QekOddkl {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.ItemTagSelector-m_null-QekOddkl::before {
  content: "";
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: white;
}

.ItemTagSelector-m_keyHelp-rSVvfBy2 {
  opacity: 0.7;
}
button .ItemTagSelector-m_keyHelp-rSVvfBy2 {
  display: none;
}

.ItemTagSelector-m_minimized-d1eXbXRg button {
  padding: 0;
  background: none;
  min-width: auto;
}
.ItemTagSelector-m_minimized-d1eXbXRg button > *:nth-child(2),
.ItemTagSelector-m_minimized-d1eXbXRg button .ItemTagSelector-m_item-P2wsn6dh > *:nth-child(2) {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/ItemTagSelector.m.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,eAAA;EACA,WAAA;AAEJ;;AAEA;EACE,OAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,OAAA;EACA,gBAAA;AAEJ;AACE;EACE,eAAA;EACA,iBAAA;AACJ;;AAGA;EAEE,wBAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AACE;EACE,WAAA;EACA,cAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,uBAAA;AACJ;;AAGA;EACE,YAAA;AAAF;AACE;EACE,aAAA;AACJ;;AAIE;EACE,UAAA;EACA,gBAAA;EACA,eAAA;AADJ;AAGE;;EAEE,wBAAA;AADJ","sourcesContent":[".itemTagSelector {\n  cursor: default;\n  button {\n    min-width: 10em;\n    width: 100%;\n  }\n}\n\n.item {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  white-space: nowrap;\n  > *:nth-child(2) {\n    flex: 1;\n    text-align: left;\n  }\n\n  :global(.app-icon) {\n    margin-top: 1px;\n    margin-right: 8px;\n  }\n}\n\n.null {\n  composes: app-icon from global;\n  display: flex !important;\n  align-items: center;\n  justify-content: center;\n  &::before {\n    content: '';\n    display: block;\n    height: 6px;\n    width: 6px;\n    border-radius: 3px;\n    background-color: white;\n  }\n}\n\n.keyHelp {\n  opacity: 0.7;\n  button & {\n    display: none;\n  }\n}\n\n.minimized {\n  button {\n    padding: 0;\n    background: none;\n    min-width: auto;\n  }\n  button > *:nth-child(2),\n  button .item > *:nth-child(2) {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemTagSelector": `ItemTagSelector-m_itemTagSelector-g1WHwbjb`,
	"item": `ItemTagSelector-m_item-P2wsn6dh`,
	"null": `ItemTagSelector-m_null-QekOddkl app-icon`,
	"keyHelp": `ItemTagSelector-m_keyHelp-rSVvfBy2`,
	"minimized": `ItemTagSelector-m_minimized-d1eXbXRg`
};
export default ___CSS_LOADER_EXPORT___;
