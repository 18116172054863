// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WishListPerkThumb-m_trashlist-H_F9Y09S {
  color: #d14334;
}

.WishListPerkThumb-m_thumb-rUeBuowE {
  background: #ddd;
  border-radius: 50%;
  color: #0b486b;
  padding: 3px;
  font-size: 8px !important;
  letter-spacing: normal;
}

.WishListPerkThumb-m_floated-N1QueZto {
  position: absolute;
  top: -4px;
  right: -5px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
}`, "",{"version":3,"sources":["webpack://./src/app/wishlists/WishListPerkThumb.m.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,yBAAA;EACA,sBAAA;AACF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,+CAAA;AAAF","sourcesContent":[".trashlist {\n  color: #d14334;\n}\n\n.thumb {\n  background: #ddd;\n  border-radius: 50%;\n  color: #0b486b;\n  padding: 3px;\n  font-size: 8px !important;\n  letter-spacing: normal;\n}\n\n// For when the thumb should appear above a perk circle\n.floated {\n  position: absolute;\n  top: -4px;\n  right: -5px;\n  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trashlist": `WishListPerkThumb-m_trashlist-H_F9Y09S`,
	"thumb": `WishListPerkThumb-m_thumb-rUeBuowE`,
	"floated": `WishListPerkThumb-m_floated-N1QueZto`
};
export default ___CSS_LOADER_EXPORT___;
