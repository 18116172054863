// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementIcon-m_element-e0VY8WPa {
  width: calc(0.16 * var(--item-size));
  height: calc(0.16 * var(--item-size));
  margin-right: 1px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  filter: saturate(2.5);
}
.destiny1 .ElementIcon-m_element-e0VY8WPa {
  width: calc(0.2 * var(--item-size));
  height: calc(0.2 * var(--item-size));
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/ElementIcon.m.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,qCAAA;EACA,iBAAA;EACA,qBAAA;EACA,4BAAA;EACA,qBAAA;EACA,qBAAA;AACF;AACE;EACE,mCAAA;EACA,oCAAA;AACJ","sourcesContent":[".element {\n  width: calc((8 / 50) * var(--item-size));\n  height: calc((8 / 50) * var(--item-size));\n  margin-right: 1px;\n  background-size: 100%;\n  background-repeat: no-repeat;\n  display: inline-block;\n  filter: saturate(2.5);\n\n  :global(.destiny1) & {\n    width: calc((10 / 50) * var(--item-size));\n    height: calc((10 / 50) * var(--item-size));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": `ElementIcon-m_element-e0VY8WPa`
};
export default ___CSS_LOADER_EXPORT___;
