// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArmorySheet-m_sheet-NHnRR1Bv.sheet {
  max-width: 900px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/armory/ArmorySheet.m.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;AACF","sourcesContent":[".sheet:global(.sheet) {\n  max-width: 900px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sheet": `ArmorySheet-m_sheet-NHnRR1Bv`
};
export default ___CSS_LOADER_EXPORT___;
