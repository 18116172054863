// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Privacy-m_privacy-jqOnvr4f ol,
.Privacy-m_privacy-jqOnvr4f p {
  color: #ccc;
  line-height: 1.2em;
}
.Privacy-m_privacy-jqOnvr4f li {
  margin-bottom: 4px;
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/shell/Privacy.m.scss"],"names":[],"mappings":"AACE;;EAEE,WAAA;EACA,kBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,eAAA;AAAJ","sourcesContent":[".privacy {\n  ol,\n  p {\n    color: #ccc;\n    line-height: 1.2em;\n  }\n  li {\n    margin-bottom: 4px;\n    margin-top: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacy": `Privacy-m_privacy-jqOnvr4f`
};
export default ___CSS_LOADER_EXPORT___;
