// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reward-m_reward-t7koxXDv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0 0;
}
.Reward-m_reward-t7koxXDv img {
  width: 17px;
  height: 17px;
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/progress/Reward.m.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACJ","sourcesContent":[".reward {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 8px 0 0 0;\n\n  img {\n    width: 17px;\n    height: 17px;\n    margin-right: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reward": `Reward-m_reward-t7koxXDv`
};
export default ___CSS_LOADER_EXPORT___;
