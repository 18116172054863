// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!../dim-ui/common.m.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArchetypeSocket-m_mod-wP6eprZ2 {
  margin-top: calc(0.08 * var(--item-size));
  margin-right: 7px;
}
.ArchetypeSocket-m_minimal-HF8qRnO_ .ArchetypeSocket-m_mod-wP6eprZ2, .ArchetypeSocket-m_isWeapons-ip8nn488 .ArchetypeSocket-m_mod-wP6eprZ2 {
  margin-top: 0;
  margin-right: 2px;
}
.ArchetypeSocket-m_mod-wP6eprZ2 .plug {
  padding: 4px;
  --item-size-mod: calc(24 / 50 * var(--item-size));
}
.ArchetypeSocket-m_mod-wP6eprZ2 .plug img {
  --item-size: var(--item-size-mod);
  transform: scale(1.4);
}
.ArchetypeSocket-m_minimal-HF8qRnO_ .ArchetypeSocket-m_mod-wP6eprZ2 .plug img {
  width: 16px !important;
  height: 16px !important;
  transform: scale(1.4);
}

.ArchetypeSocket-m_row-u85T5wvj {
  align-items: flex-start;
  padding: 6px 10px;
  margin-bottom: 4px;
  background-color: var(--theme-item-popup-panel-bg);
  width: 100%;
  border-bottom: none;
  box-sizing: border-box;
}
.ArchetypeSocket-m_row-u85T5wvj.ArchetypeSocket-m_minimal-HF8qRnO_ {
  background-color: transparent;
  align-items: center;
  padding: 0 4px;
  margin: 0;
}
.armory .ArchetypeSocket-m_row-u85T5wvj {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.ArchetypeSocket-m_row-u85T5wvj.ArchetypeSocket-m_isWeapons-ip8nn488 {
  align-items: center;
}
.ArchetypeSocket-m_row-u85T5wvj.ArchetypeSocket-m_isWeapons-ip8nn488.ArchetypeSocket-m_minimal-HF8qRnO_ {
  padding-left: 0;
}

.ArchetypeSocket-m_info-vkafEp1X {
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 0%;
  margin-right: 8px;
}

.ArchetypeSocket-m_name-stGGmYUc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/ArchetypeSocket.m.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,iBAAA;AACF;AAAE;EAEE,aAAA;EACA,iBAAA;AACJ;AAGE;EACE,YAAA;EAEA,iDAAA;AAFJ;AAII;EACE,iCAAA;EACA,qBAAA;AAFN;AAIM;EACE,sBAAA;EACA,uBAAA;EACA,qBAAA;AAFR;;AAQA;EAEE,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kDAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;AALF;AAOE;EACE,6BAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;AALJ;AAQE;EACE,8BAAA;EACA,kCAAA;UAAA,0BAAA;AANJ;AASE;EACE,mBAAA;AAPJ;AASI;EACE,eAAA;AAPN;;AAYA;EACE,sBAAA;EACA,uBAAA;EACA,OAAA;EACA,SAAA;EACA,iBAAA;AATF;;AAYA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AATF","sourcesContent":[".mod {\n  margin-top: calc(4 / 50 * var(--item-size));\n  margin-right: 7px;\n  .minimal &,\n  .isWeapons & {\n    margin-top: 0;\n    margin-right: 2px;\n  }\n\n  // Shrink down the intrinsic perk - it's super large\n  :global(.plug) {\n    padding: 4px;\n\n    --item-size-mod: calc(24 / 50 * var(--item-size));\n\n    img {\n      --item-size: var(--item-size-mod);\n      transform: scale(1.4);\n\n      .minimal & {\n        width: 16px !important;\n        height: 16px !important;\n        transform: scale(1.4);\n      }\n    }\n  }\n}\n\n.row {\n  composes: flexRow from '../dim-ui/common.m.scss';\n  align-items: flex-start;\n  padding: 6px 10px;\n  margin-bottom: 4px;\n  background-color: var(--theme-item-popup-panel-bg);\n  width: 100%;\n  border-bottom: none;\n  box-sizing: border-box;\n\n  &.minimal {\n    background-color: transparent;\n    align-items: center;\n    padding: 0 4px;\n    margin: 0;\n  }\n\n  :global(.armory) & {\n    background: rgba(0, 0, 0, 0.5);\n    backdrop-filter: blur(6px);\n  }\n\n  &.isWeapons {\n    align-items: center;\n\n    &.minimal {\n      padding-left: 0;\n    }\n  }\n}\n\n.info {\n  flex-direction: column;\n  align-items: flex-start;\n  flex: 1;\n  width: 0%;\n  margin-right: 8px;\n}\n\n.name {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mod": `ArchetypeSocket-m_mod-wP6eprZ2`,
	"minimal": `ArchetypeSocket-m_minimal-HF8qRnO_`,
	"isWeapons": `ArchetypeSocket-m_isWeapons-ip8nn488`,
	"row": `ArchetypeSocket-m_row-u85T5wvj ${___CSS_LOADER_ICSS_IMPORT_0___.locals["flexRow"]}`,
	"info": `ArchetypeSocket-m_info-vkafEp1X`,
	"name": `ArchetypeSocket-m_name-stGGmYUc`
};
export default ___CSS_LOADER_EXPORT___;
