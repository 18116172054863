// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MetricCategories-m_list-C0XI0OWi {
  list-style: none;
  margin: 0;
  padding: 0;
}
.MetricCategories-m_list-C0XI0OWi li {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}
.MetricCategories-m_list-C0XI0OWi li:last-child {
  margin-bottom: 0;
}

.MetricCategories-m_icon-UO8WoB0J {
  height: 15px;
  width: 15px;
  margin-right: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/item-popup/MetricCategories.m.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,SAAA;EACA,UAAA;AACF;AACE;EACE,SAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACE,gBAAA;AAEN;;AAGA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AAAF","sourcesContent":[".list {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n\n  li {\n    margin: 0;\n    padding: 0;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 4px;\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n}\n\n.icon {\n  height: 15px;\n  width: 15px;\n  margin-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `MetricCategories-m_list-C0XI0OWi`,
	"icon": `MetricCategories-m_icon-UO8WoB0J`
};
export default ___CSS_LOADER_EXPORT___;
