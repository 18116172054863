// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-m_flexWrap-Xahgc1PB {
  display: flex;
  flex-flow: row wrap;
}

.common-m_flexRow-ZPndn63a {
  display: flex;
  flex-direction: row;
}

.common-m_flexColumn-xaQidJJA {
  display: flex;
  flex-direction: column;
}

.common-m_resetButton-o3oKAnUN {
  text-align: center;
  color: inherit;
  -webkit-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
}
.common-m_resetButton-o3oKAnUN:disabled {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/app/dim-ui/common.m.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,kBAAA;EACA,cAAA;EACA,wBAAA;UAAA,gBAAA;EACA,uBAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".flexWrap {\n  display: flex;\n  flex-flow: row wrap;\n}\n\n.flexRow {\n  display: flex;\n  flex-direction: row;\n}\n\n.flexColumn {\n  display: flex;\n  flex-direction: column;\n}\n\n.resetButton {\n  text-align: center;\n  color: inherit;\n  appearance: none;\n  background: transparent;\n  border: 0;\n  padding: 0;\n  margin: 0;\n  cursor: pointer;\n  font-size: inherit;\n  font-family: inherit;\n  &:disabled {\n    cursor: default;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexWrap": `common-m_flexWrap-Xahgc1PB`,
	"flexRow": `common-m_flexRow-ZPndn63a`,
	"flexColumn": `common-m_flexColumn-xaQidJJA`,
	"resetButton": `common-m_resetButton-o3oKAnUN`
};
export default ___CSS_LOADER_EXPORT___;
