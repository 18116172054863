// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompareButtons-m_svgIcon-IkWLsrKg {
  filter: drop-shadow(0 0 1px black);
}
.CompareButtons-m_svgIcon-IkWLsrKg img {
  height: 1.3em;
  margin: 2.5px;
}

.CompareButtons-m_inlineImageIcon-E0alBW9Q {
  display: inline-block;
  vertical-align: middle;
  height: 1.3em;
  width: 1.3em;
}

.CompareButtons-m_intrinsicIcon-eiv4x04H {
  transform: scale(1.2);
}`, "",{"version":3,"sources":["webpack://./src/app/compare/CompareButtons.m.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;AAAE;EACE,aAAA;EACA,aAAA;AAEJ;;AAEA;EACE,qBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,qBAAA;AACF","sourcesContent":[".svgIcon {\n  filter: drop-shadow(0 0 1px black);\n  img {\n    height: 1.3em;\n    margin: 2.5px;\n  }\n}\n\n.inlineImageIcon {\n  display: inline-block;\n  vertical-align: middle;\n  height: 1.3em;\n  width: 1.3em;\n}\n\n.intrinsicIcon {\n  transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgIcon": `CompareButtons-m_svgIcon-IkWLsrKg`,
	"inlineImageIcon": `CompareButtons-m_inlineImageIcon-E0alBW9Q`,
	"intrinsicIcon": `CompareButtons-m_intrinsicIcon-eiv4x04H`
};
export default ___CSS_LOADER_EXPORT___;
